.cover_component {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
  position: relative;

  &__information {
    margin-bottom: 40px;
    position: absolute;
  }
}
