.carousel_component {
  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: 0% 15%;
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: top;
    }
  }
}
