.count_down_component {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__interval {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }

  &__interval:last-child {
    margin-right: 0px;
  }
}
