.WAConfirmation_component {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;

  &__link {
    border-radius: 8px;
    border: none;
    background-color: #00a884;
    color: white;
    padding: 15px 30px;
    font-size: 18px;

    svg,
    span {
      vertical-align: middle;
      display: inline-block;
    }
  }

  &__link:hover {
    color: white;
    cursor: pointer;
  }
}
