.component__venue-card {
  margin-bottom: 20px;
  .ui.card {
    padding: 20px;
    margin: 0px 50px;

    .header {
      font-weight: bold;
      text-transform: uppercase;
    }

    .image {
      border-radius: 10px;
    }
  }
}
