.component__event-details {
  text-align: center;

  .cover_component__information {
    color: white;

    h2 {
      font-family: "Josefin Slab", serif;
      font-size: 3vw;
    }

    h3 {
      font-family: "Vibur", cursive;
      font-size: 5vw;
      margin-top: 0px;
    }

    .count_down_component {
      font-family: "Vibur", cursive;
      font-size: 2vw;

      &__interval__number {
        margin-bottom: 20px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .cover_component__information {
      margin-bottom: 20px;

      h3 {
        margin-bottom: 0px;
      }

      .count_down_component {
        &__interval__number {
          margin-bottom: 0px;
        }
      }
    }
  }
}
