.component__event-details {
  text-align: center;

  .cover_component__information {
    color: white;

    h2 {
      font-family: "Josefin Slab", serif;
      font-size: 3vw;
    }

    h3 {
      font-family: "Vibur", cursive;
      font-size: 5vw;
      margin-top: 0px;
    }

    .count_down_component {
      font-family: "Vibur", cursive;
      font-size: 2vw;

      &__interval__number {
        margin-bottom: 20px;
      }
    }
  }

  .backCover_component__quote {
    font-family: "Handlee", cursive;
    font-size: 4vw;
    line-height: 5vw;
    padding: 20px;
    color: white;
  }

  .component__venue-list {
    h1 {
      font-family: "Vibur", cursive;
      font-size: 3vw;
      color: #06448d;
    }
  }

  .component__venue-card {
    .header {
      font-family: "Overlock SC", cursive;
      font-size: 1.5vw;
    }

    .description {
      font-family: "Handlee", cursive;
      font-size: 1.2vw;
    }
  }

  .component__venue-card:first-child {
    .card {
      background-color: #06448d;
      color: white;
      .component__map {
        .component__map-button {
          border: 1px solid white;
        }
      }
    }
  }

  .component__venue-card:last-child {
    .card {
      background-color: #53aadb;
      color: white;
      .component__map {
        .component__map-button {
          border: 1px solid white;
          background-color: #53aadb;
          color: white;
        }
      }
    }
  }

  .component__map {
    .component__map-button {
      background-color: #06448d;
      color: white;
      border: none;
      border-radius: 3px;
      padding: 8px;
      cursor: pointer;
    }
  }

  .component_dressCode {
    h2 {
      font-family: "Vibur", cursive;
      font-size: 3vw;
      color: #06448d;
    }
    .component_dressCode__list__element {
      font-size: 5vw;
      color: #53aadb;
      margin-bottom: 2vw;
    }
    .component_dressCode__description {
      font-family: "Handlee", cursive;
      font-size: 1.5vw;
    }
  }

  .component_giftList {
    margin-top: 20px;
    h2 {
      font-family: "Vibur", cursive;
      font-size: 3vw;
      color: #06448d;
    }
    .component_giftList__list__element {
      font-size: 5vw;
      color: #53aadb;
    }
  }

  .WAConfirmation_component {
    h2 {
      font-family: "Vibur", cursive;
      font-size: 3vw;
      color: #06448d;
    }
    .WAConfirmation_component__instructions {
      font-family: "Handlee", cursive;
      font-size: 1.5vw;
    }
    .WAConfirmation_component__note {
      font-family: "Handlee", cursive;
      font-size: 1.5vw;
      margin-top: 8px;
    }
    .WAConfirmation_component__form--name {
      font-family: "Handlee", cursive;
      font-size: 1.5vw;
      margin-top: 20px;
      margin-bottom: 10px;
      input {
        border: none;
        border-bottom: 1px solid;
      }
    }
    .WAConfirmation_component__link {
      font-family: "Handlee", cursive;
    }
  }

  .closing_component {
    img {
      object-fit: cover;
      object-position: 0% 25%;
    }
    .closing_component__text {
      padding: 20px;
      color: white;

      h2 {
        font-family: "Josefin Slab", serif;
        font-size: 3vw;
      }

      .closing_component__quote {
        font-family: "Vibur", cursive;
        font-size: 5vw;
        line-height: 5vw;
      }
    }
  }

  .audioPlayer__component {
    &_button {
      font-size: 4vw;
      color: white;
      background-color: #06448d;
      border: 1px solid #06448d;
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 820px) {
    .cover_component__information {
      margin-bottom: 40px;

      h2 {
        font-size: 6vw;
      }

      h3 {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0 200px;
        font-size: 12vw;
      }

      .count_down_component {
        font-size: 5vw;

        &__interval__number {
          margin-bottom: 20px;
        }
      }
    }

    .backCover_component__quote {
      font-size: 5vw;
      line-height: 7vw;
    }

    .component__venue-list {
      h1 {
        font-size: 4vw;
      }
    }

    .component__venue-card {
      .header {
        font-size: 2.2vw;
      }

      .description {
        font-size: 2vw;
      }
    }

    .component_dressCode {
      h2 {
        font-size: 4vw;
      }
      .component_dressCode__list__element {
        font-size: 8vw;
        margin-bottom: 2vw;
      }
      .component_dressCode__description {
        font-size: 2.5vw;
      }
    }

    .component_giftList {
      h2 {
        font-size: 4vw;
      }
      .component_giftList__list__element {
        font-size: 8vw;
      }
    }

    .WAConfirmation_component {
      h2 {
        font-size: 4vw;
      }
      .WAConfirmation_component__instructions {
        font-size: 2vw;
      }
      .WAConfirmation_component__note {
        font-size: 2vw;
      }
      .WAConfirmation_component__form--name {
        font-size: 2vw;
        input {
          border-bottom: 1px solid;
        }
      }
    }

    .closing_component {
      img {
        object-position: top;
      }
      .closing_component__text {
        h2 {
          font-size: 6vw;
        }
        .closing_component__quote {
          font-size: 7vw;
          line-height: 9vw;
        }
      }
    }
  }

  @media only screen and (max-width: 450px) {
    .cover_component__information {
      margin-bottom: 40px;

      h2 {
        font-size: 8vw;
      }

      h3 {
        font-size: 16vw;
        padding: 0 65px;
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .count_down_component {
        font-size: 6vw;

        &__interval__number {
          margin-bottom: 0px;
        }
      }
    }

    .backCover_component {
      margin-bottom: 20px;

      .backCover_component__quote {
        font-size: 5vw;
        line-height: 7vw;
      }
    }

    .component__venue-list {
      h1 {
        font-size: 6.5vw;
      }
    }

    .component__venue-card {
      .header {
        font-size: 4.5vw;
      }

      .description {
        font-size: 4vw;
      }
    }

    .component_dressCode {
      h2 {
        font-size: 6.5vw;
      }
      .component_dressCode__list__element {
        font-size: 10vw;
        margin-bottom: 2vw;
      }
      .component_dressCode__description {
        font-size: 4vw;
      }
    }

    .component_giftList {
      h2 {
        font-size: 6.5vw;
      }
      .component_giftList__list__element {
        font-size: 10vw;
      }
    }

    .WAConfirmation_component {
      h2 {
        font-size: 6.5vw;
      }
      .WAConfirmation_component__instructions {
        font-size: 4vw;
      }
      .WAConfirmation_component__note {
        font-size: 4vw;
        margin-top: 4px;
        padding: 0px 30px;
      }
      .WAConfirmation_component__form--name {
        font-size: 4vw;
        margin-top: 10px;
        margin-bottom: 10px;
        input {
          border: none;
          border-bottom: 1px solid;
        }
      }
    }

    .closing_component {
      img {
        object-position: top;
      }
      .closing_component__text {
        h2 {
          font-size: 7vw;
        }
        .closing_component__quote {
          font-size: 8vw;
          line-height: 10vw;
        }
      }
    }
  }
}

.component__map_modal {
  h3 {
    font-family: "Handlee", cursive;
    font-size: 1.5vw;
  }

  .component__map-button--close {
    background-color: #06448d;
    color: white;
    border: none;
    border-radius: 3px;
    padding: 8px;
    cursor: pointer;
  }
}
