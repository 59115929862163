.audioPlayer__component {
  position: fixed;
  top: 45%;
  right: 0;

  &_button {
    border: none;
    border-radius: 5px 0 0 5px;
    padding: 10px 10px 7px 10px;
  }
}
