.image_component {
  width: 100%;

  img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: top;
  }

  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      object-position: center;
    }
  }
}
