.closing_component {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;
  position: relative;

  &__text {
    margin-bottom: 40px;
    position: absolute;
  }
}
